<template>
    <nav v-if="show && hasAction" id="menu-aux" :class="[$mq]">
        <div class="aux-actions">
            <ul>
                <li v-for="(item, index) in actions" :key="index" v-if="item.show" :class="[index, { opened: item.opened }, { expand: item.expand }, { 'no-icon': item.icon == '' }, item.customClass ? item.customClass : '']" class="act show" @click="item.callback">
                    {{ item.label }}
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            actions: {}
            // hasAction: this.actions.reduce((a, v) => (v.show == 1 ? a + 0 : a), 0)
        }
    },
    computed: {
        hasAction() {
            if (this.actions) {
                var result = Object.values(this.actions).filter((obj) => {
                    return obj.show == 1
                })
                return result.length > 0
            }
            return false
        }
    },
    methods: {
        hide(event) {
            if (!this.closable) {
                return
            }
            this.show = false
            document.body.style.overflow = 'auto'
        }
    }
}
</script>

<style lang="scss">
#menu-aux {
    position: fixed;
    bottom: $alex-app-padding;
    right: $alex-app-padding;
    z-index: $z-menu-aux;
    max-width: calc(100% - #{$menu-tools-min});
    width: auto;
    height: $menu-aux-height;
    overflow: hidden;

    &.full {
        width: calc(100% - #{$menu-tools-min});
    }

    .aux-nav,
    .aux-actions {
        height: $menu-aux-height;
        overflow: hidden;

        ul {
            height: 100%;
            list-style: none;
            display: inline-block;
            text-align: center;

            li {
                cursor: pointer;
            }
        }
    }

    .aux-actions {
        float: right;
        
        ul {
            margin-right: 0px;

            li {
                $size: rem(50px);
                $rest: rem(50px);
                $ico: rem(24px);
                $icoS: rem(18px);

                @include background($size: $ico, $color: #666);
                @include text-ellipsis();
                @include font-size(ml);
                @include align-items();
                @include border-radius($size);
                display: none;
                font-family: $text-bold;
                position: relative;
                margin: 0 6px $size 6px;
                height: $size;
                overflow: hidden;
                transition: all 0.2s ease 0.3s;
                transition: color 0s ease 0s;
                width: auto;
                background-position: left ($size / 4) center;
                padding: 0 ($size / 2) 0 $size;
                color: #fff;

                &.no-icon {
                    background-image: none;
                }

                &.save {
                    background-image: img('save_ffffff.svg') !important;
                    background-color: $done !important;
                    background-size: $ico + rem(8px);
                }
                &.confirm {
                    background-image: img('save_ffffff.svg') !important;
                    background-color: $done !important;
                    background-size: $ico + rem(8px);
                }
                &.create {
                    background-image: img('more_ffffff.svg') !important;
                    background-color: $done !important;
                }
                &.create-portrait {
                    background-image: img('add_ffffff.svg') !important;
                    background-color: $done !important;
                }
                &.continue {
                    background-image: img('right_light.svg') !important;
                    background-color: $progress;
                }
                &.print {
                    background-image: img('printer_ffffff.svg') !important;
                    background-color: $main !important;
                }
                &.print-info {
                    @include justify-content();
                    background-color: #666;
                }
                &.edit {
                    background-image: img('edit_ffffff.svg') !important;
                    background-color: $warning !important ;
                }
                &.delete {
                    background-image: img('delete_ffffff.svg') !important;
                    background-color: $error !important;
                    background-size: $ico - rem(1px);
                }
                &.cancel {
                    background-image: img('close_ffffff.svg') !important;
                    background-color: $main-s10;
                    background-size: $ico - rem(2px);
                }
                &.back {
                    background-image: img('left_ffffff.svg') !important;
                    background-color: $main-s10 !important;
                }
                &.expand {
                    &.back-portrait {
                        // color: $neutro-s60;
                    }
                }
                &.back-portrait {
                    background-image: img('left_dark.svg') !important;
                    background-color: $default-sec-color !important;
                }
                &.search {
                    background-image: img('search_light.svg') !important;
                    background-color: $main-s10 !important;
                }
                &.create-ticket {
                    background-image: img('send_ffffff.svg') !important;
                    background-color: $done !important;
                }
                &.send-ticket {
                    background-image: img('tick_ffffff.svg') !important;
                    background-color: $done !important;
                    opacity: 0.5;
                    cursor: not-allowed;
                }
                &.send-ticket-ok {
                    background-image: img('tick_ffffff.svg') !important;
                    background-color: $done !important;
                }
                &.helpcenter {
                    background-image: img('info_s80.svg') !important;
                    background-color: $info !important;
                    background-size: $ico - rem(2px);
                    color: $info-s80;
                }
                &.chat {
                    background-image: img('chat.svg') !important;
                    background-color: $accent-dark !important;
                    background-size: $ico - rem(2px);
                }

                &.disabled {
                    color: #888 !important;
                    background-color: #555 !important;
                    cursor: not-allowed;
                }
                &.show {
                    display: inline-flex;
                }

                a,
                span {
                    @include display-flex();
                    @include flex-direction(row);
                    @include flex-wrap();
                    @include justify-content(flex-end);
                    @include align-items();
                    @include align-content();
                    @include font-size(ml);
                    line-height: $size;
                    height: 100%;
                    padding: 15px;
                }

                // TABLET/MOBILE
                // @include media('<desktop') {
                //     width: auto;
                //     background-position: left ($size / 4) center;
                //     padding: 0 ($size / 2) 0 $size;
                //     color: #fff;

                //     &.expand {
                //         &.back-portrait {
                //             color: $neutro-s60;
                //         }
                //     }
                // }

                // // DESKTOP
                // @include media('>=desktop') {
                //     width: auto;
                //     background-position: left ($size / 4) center;
                //     padding: 0 ($size / 2) 0 $size;
                //     color: #fff;

                //     &.expand {
                //         &.back-portrait {
                //             color: $neutro-s60;
                //         }
                //     }
                // }
            }
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss">
#menu-aux.portrait {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: $z-menu-aux;
    max-width: none;
    height: $menu-aux-height;
    overflow: hidden;
    // width: 100%;
    width: calc(100% - #{$scroll-width});
    transition: 0.2s;
    pointer-events: none;
    @include background-gradient(70%, $default-bg-color);
    padding-top: 10px;

    &.full {
        // width: 100%;
        transition: 0.2s;
    }

    .aux-nav,
    .aux-actions {
        height: $menu-aux-height;
        overflow: hidden;
        width: 100%;
        text-align: center;
        transition: 0.2s;
        @media (max-width: 390px) {
            width: 101%;
        }
        ul {
            height: 100%;
            width: 100%;
            list-style: none;
            display: inline-block;
            transition: 0.2s;

            li {
                @include display-inline-flex();
                @include font-size(s);
                @include border-radius(4px);
                @include text-ellipsis();
                @include justify-content(center);
                // background-color: $main;
                cursor: pointer;
                width: auto;
                // background-position: left ($size / 4) center;
                // padding: 0 ($size / 2) 0 $size;
                color: #fff;
                background-image: none;
                margin: 0 2px;
                text-align: center;
                min-width: unset;
                max-width: 90vw;
                width: max-content;
                width: calc(50% - 22px);
                transition: 0.2s;
                padding: 0 15px 0 45px !important;
                height: 50px;
                pointer-events: initial;
                float: right;
                // display: inline-grid;
                background-position: left 10px center;
                text-transform: uppercase;
                font-family: $text-bold;
                padding-left: 30px;
                background-size: 30px !important;
                white-space: normal;
                line-height: 20px;

                &:hover {
                    // padding: inherit;
                    padding: 0 15px 0 45px !important;
                    background-position: left 10px center;
                    transition: 0.2s;
                    width: calc(50% - 22px);
                }

                &li.act.cancel.password-cancel {
                    background-image: none !important;
                    background-color: $neutro !important;
                    color: $main;
                    position: relative;
                    top: 20px;
                    right: 15px;
                    height: fit-content !important;
                    width: fit-content !important;
                    padding: 8px 30px !important;
                }
                &li.act.continue.password-continue {
                    background-image: none !important;
                    position: relative;
                    background-color: $main-dark !important;
                    color: #fff;
                    top: 20px;
                    left: 15px;
                    height: fit-content !important;
                    width: fit-content !important;
                    padding: 8px 30px !important;
                }
                &.save {
                }
                &.confirm {
                }
                &.create {
                    background-size: 20px !important;
                    background-position: left 15px center;
                }
                &.continue {
                }
                &.back-portrait {
                    color: $neutro-s60;
                }
                &.print {
                }
                &.print-info {
                }
                &.edit {
                }
                &.delete {
                }
                &.cancel {
                    background-size: 20px !important;
                    background-position: left 15px center;
                }
                &.back {
                    background-size: 20px !important;
                    background-position: left 15px center;
                    float: none;
                }
                &.search {
                }
                &.create-ticket {
                    // float: none !important;
                }
                &.send-ticket {
                }
                &.send-ticket-ok {
                }
                &.helpcenter {
                }

                &.disabled {
                }

                &.full {
                    width: max-content;
                }
                &.left {
                    float: left;
                }
            }
        }
    }
}
</style>
