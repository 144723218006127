import Vue from 'vue'
import { Api } from '@/core/api.js'

import localforage from 'localforage'
const api = new Api()

var db_employees = localforage.createInstance({ name: 'alexdb', storeName: 'employee' })
var db_roles = localforage.createInstance({ name: 'alexdb', storeName: 'roles' })
const db_login = localforage.createInstance({ name: 'alexdb', storeName: 'login' })

// Triquiñuela para resetear el state
const getDefaultState = () => {
    return {
        employees: {},
        current_employee_validating_external: typeof localStorage.current_employee_validating_external !== 'undefined' ? localStorage.current_employee_validating_external : false,
        errorValidatingExternalLogin: false,
        filters: {
            roles: {}
        }
    }
}

// initial state
const state = getDefaultState()

;(async () => {
    const roles = await db_login.getItem('roles')
    state.filters.roles = roles

    let employees = []
    await db_employees.iterate(function(value, key) {
        log("db_employees iterate", value, key)
        employees[key] = value
    })
    
    state.employees = {...employees}
})()

// getters
const getters = {
    getAllEmployees: (state, getters, rootState) => {
        return state.employees
    },
    getLoginUsers: (state, getters, rootState) => (type) => {
        type = type || 0

        var returnUsers = {}
        for (var k in state.employees) {
            var e = state.employees[k]
            var role = e.rol && state.filters.roles && state.filters.roles[e.rol] ? state.filters.roles[e.rol] : false
            if (role && role.level >= type) {
                returnUsers[k] = e
            }
        }
        return returnUsers
        // return _.filter(state.employees, function(e) {
        //   return e.type >= type && e.type < limit && e.conf.login;
        // });
        // return state.employees
    },
    getAccessUsers: (state, getters, rootState) => (type) => {
        // type = type ? type : 0;
        // let limit = type >= 3 ? 4 : 3;

        // return _.filter(state.employees, function(e) {
        //   return e.type >= type && e.type < limit;
        // });
        // return state.employees
        log('getAccessUers', type)
        return _.filter(state.employees, function (e) {
            var role = e.rol && state.filters.roles[e.rol] ? state.filters.roles[e.rol] : false
            log(e.name, e.rol, role.level)
            return role && role.level >= type
        })
    },

    getClockEmployees: (state, getters, rootState) => {
        var result = state.employees

        Object.keys(result).forEach(function (key) {
            var user = result[key]

            if (user.last_session.start_date != false && user.last_session.end_date == false) {
                user.clockClass = 'clocked-in'
                if (moment().diff(moment(user.last_session.start_date * 1000), 'hours') >= 12) {
                    user.clockClass = 'clocked-out'
                }
            } else {
                user.clockClass = 'clocked-out'
            }
        })

        return result
    },

    getEmployee: (state, getters, rootState) => (id) => {
        return state.employees[id]
    },
    getAllUsers: (state, getters, rootState) => {
        return _.filter(state.employees, function (e) {
            return e.type > 1 && e.type < 3
        })
    },

    getEmployeeAccess: (state, getters, rootState) => (id, level) => {
        if (state.employees[id].type >= level) {
            return true
        } else {
            return false
        }
    },

    validateAccess: (state, getters, rootState) => (user_id, pass) => {
        var user = state.employees[user_id]

        if (user && user.password == sha256(pass)) return true
        else return false
    },

    getAllRoles: (state, getters, rootState) => {
        return state.filters.roles
    },

    getRol: (state, getters, rootState) => (id) => {
        return state.filters.roles[id]
    },

    getCurrentEmployeeValidatingExternal: (state, getters, rootState) => {
        return state.current_employee_validating_external
    },

    getErrorValidatingExternalLogin: (state, getters, rootState) => {
        return state.errorValidatingExternalLogin
    }
}

// actions
const actions = {
    // Commonisimos, está en todos los modules
    resetState({ commit }) {
        commit('resetState')
    },

    doClock(context, params) {
        var responseState = false

        return api
            .post('employee/clock/' + params.type, { employee: params.employee, password: params.password })
            .then(function (response) {
                context.commit('clocked', params)
                if (response.status) responseState = true
            })
            .then(function () {
                return responseState
            })
    },
    responseNPS(context, params) {
        return api
            .post('nps/answer', params)
            .then(function (response) {
                if (params.employee_id) {
                    context.commit('updateNPS', params.employee_id)
                }
            })
            .catch(function (response) {
                logError(response)
                if (params.employee_id) {
                    context.commit('updateNPS', params.employee_id)
                }
            })
    },

    sendSpeedTestResult(context, params) {
        return api.post('location/connection/velocity', params).then(function (response) {
            return response
        })
    },

    loadRoles(context) {
        if (fnCheckConnection()) {
            return api
                .get('admin/employee/roles')
                .then(function (response) {
                    if (response.status) {
                        db_roles.clear()

                        _.forEach(response.data, function (value, key) {
                            db_roles.setItem(key, value)
                        })

                        context.commit('setRoles', response.data)

                        return true
                    } else {
                        return loadRolesOffline(context)
                    }
                })
                .catch(function (error) {
                    logError(error)
                    return loadRolesOffline(context)
                })
        } else {
            return loadRolesOffline(context)
        }
    },

    changeAvatar(context, params) {
        return api.post('admin/employee/' + params.id + '/newavatar', { avatar: params.avatar }).then(function (response) {
            if (response) {
                return context.commit('setAvatar', response.data)
            }
        })
    },
    updatePin(context, params) {
        return api.post('admin/employee/' + params.id + '/newpin', { pin: params.pin }).then(function (response) {
            if (response) {
                context.commit('setPin', response.data)
            }
        })
    },

    loadEmployees(context, params) {
        if ((context.rootState.refresh.employee || (typeof params !== 'undefined' && typeof params.force !== 'undefined' && params.force)) && fnCheckConnection()) {
            var employees = []
            return db_employees
                .iterate(function (value, key, iterationNumber) {
                    if (value.is_admin) employees[key] = value
                })
                .then(function () {
                    return api.get('employee', {}).then(function (response) {
                        let data = format_employees(response)
                        for (var k in employees) {
                            data[k] = employees[k]
                        }
                        context.commit('setEmployees', data)

                        db_employees.clear()

                        _.forEach(data, function (value, key) {
                            db_employees.setItem(key, value)
                        })

                        context.rootState.refresh.employee = false

                        return true
                    })
                })
                .catch(function (error) {
                    logError(error)
                    let employees = []
                    return db_employees
                        .iterate(function (value, key, iterationNumber) {
                            employees[key] = value
                        })
                        .then(function () {
                            if (_.size(_.keys(employees)) > 0) {
                                context.commit('setEmployees', employees)
                            }
                            return false
                        })
                })
        } else {
            var employees = []
            return db_employees
                .iterate(function (value, key, iterationNumber) {
                    employees[key] = value
                })
                .then(function () {
                    if (_.size(_.keys(employees)) > 0) {
                        context.commit('setEmployees', employees)
                    }
                    return false
                })
        }
    },
    addNewEmployee(context, params) {
        params.pin = sha256(params.password)
        var user = {
            name: params.name,
            surname: params.surname,
            rol: params.rol,
            pin: params.pin,
            avatar: params.avatar ? params.avatar : null
        }
        delete params.password
        return api
            .post('employee/app/add', user)
            .then(function (response) {
                if (response && response.status) {
                    context.commit('addEmployee', response.data)
                    return response.status
                }
                return false
            })
            .catch(function (error) {
                logError(error)
                return false
            })
    },
    editEmployee(context, params) {
        var user = {
            name: params.name,
            surname: params.surname,
            rol: params.rol,
            pin: params.pin
        }
        return api
            .post('employee/app/' + params.id + '/edit', user)
            .then(function (response) {
                if (response) {
                    context.commit('editEmployee', response.data)
                    return response.status
                }
            })
            .catch(function (error) {
                logError(error)
                return falses
            })
    },
    removeEmployee(context, params) {
        return api
            .post(`employee/app/${params.id}/delete`, {})
            .then((response) => {
                if (response) {
                    context.commit('removeEmployee', params.id)
                    return response.status
                }
            })
            .catch((error) => {
                logError(error)
                return false
            })
    },

    validateExternalAccess(context, params) {
        return new Promise((resolve, reject) => {
            const currentValidatingEmployeeId = context.getters.getCurrentEmployeeValidatingExternal
            context.commit('setCurrentEmployeeValidatingExternal', false)
            log('validateExternalAccess', params.id, currentValidatingEmployeeId)
            if (params.id && params.id == currentValidatingEmployeeId) {
                const employee = context.getters.getEmployee(params.id)
                log('validateExternalAccess', employee)
                context
                    .dispatch('loginUser/doLogin', { employee: params.id, password: employee.password }, { root: true })
                    .then(() => {
                        log('validateExternalAccess', 'LOGIN OK')
                        resolve()
                    })
                    .catch((err) => {
                        log('validateExternalAccess', 'LOGIN ERROR')
                        context.commit('setErrorValidatingExternalLogin', true)
                        reject(err)
                    })
            } else {
                log('validateExternalAccess', 'NO SE CORRESPONDEN LOS ID')
                context.commit('setErrorValidatingExternalLogin', true)
                reject('VALIDATE EXTERNAL ACCESS INCORRECT IDS')
            }
        })
    }
}

// mutations
const mutations = {
    // Commonisimos, está en todos los modules
    resetState(state) {
        Object.assign(state, getDefaultState())
        db_employees.clear()
    },
    async updateNPS(state, employee_id) {
        const employee = await db_employees.getItem(employee_id)
        const loginEmployee = await db_login.getItem('user')

        employee.show_nps = false
        loginEmployee.show_nps = false

        db_employees.setItem(employee_id, employee)
        db_login.setItem('user', loginEmployee)
    },

    setEmployees(state, response) {
        var aItems = response

        Object.keys(aItems).map(function (item_id, i) {
            var emp = aItems[item_id]

            emp.conf = emp.conf ? emp.conf : {}
            emp.conf['sign'] = typeof emp.conf.sign !== 'undefined' ? emp.conf.sign : true
            emp.conf['login'] = typeof emp.conf.login !== 'undefined' ? emp.conf.login : true
        })
        var employees = { ...state.employees }
        for (var k in aItems) {
            employees[k] = aItems[k]
        }
        state.employees = employees
        Vue.set(state, 'employees', { ...employees })
    },

    addEmployee(state, empl) {
        var employees = { ...state.employees }
        var avatar = empl.avatar
        if (!avatar.includes('//')) {
            empl.avatar = css_image_url + 'avatar/' + avatar
        }
        employees[empl.id] = empl
        db_employees.setItem(empl.id, empl)
        state.employees = employees
        Vue.set(state, 'employees', { ...employees })
    },
    editEmployee(state, empl) {
        var employees = { ...state.employees }
        var avatar = empl.avatar
        if (!avatar.includes('//')) {
            empl.avatar = css_image_url + 'avatar/' + avatar
        }
        employees[empl.id] = empl
        db_employees.setItem(empl.id, empl)
        state.employees = employees
        Vue.set(state, 'employees', { ...employees })
    },

    clocked(state, params) {
        var emp = state.employees[params.employee]
        if (params.type == 0) {
            emp.last_session.end_date = moment().unix()
        } else {
            emp.last_session.end_date = false
            emp.last_session.start_date = moment().unix()
        }
    },
    setAvatar(state, params) {
        var emp = { ...state.employees }
        var avatar = params.avatar
        if (!avatar.includes('//')) {
            params.avatar = css_image_url + 'avatar/' + params.avatar
        }
        emp[params.id].avatar = params.avatar
        emp[params.id].show_nps = params.show_nps
        state.employees = emp
        Vue.set(state, 'employees', { ...emp })
    },
    setPin(state, params) {
        var emp = { ...state.employees }
        emp[params.id] = params
        state.employees = emp
        log(state.employees)
        Vue.set(state, 'employees', { ...emp })
    },

    setRoles(state, payload) {
        var filters = state.filters
        filters.roles = payload
        state.filters = filters
        db_login.setItem('roles', filters.roles)
        Vue.set(state.filters, 'filters', { ...filters })
    },

    setConditionsVersion(state, params) {
        let employees = { ...state.employees }
        employees[params.employee_id].legal_version = params.conditions_version
        state.employees = employees
        Vue.set(state, 'employees', { ...employees })
    },
    removeEmployee(state, employeeId) {
        let employees = { ...state.employees }
        delete employees[employeeId]
        db_employees.removeItem(employeeId)
        state.employees = employees
        Vue.set(state, 'employees', { ...employees })
    },
    setCurrentEmployeeValidatingExternal(state, employeeId) {
        state.current_employee_validating_external = employeeId
        localStorage.setItem('current_employee_validating_external', employeeId)
    },
    setErrorValidatingExternalLogin(state, value) {
        state.errorValidatingExternalLogin = value
    }
}

function format_employees(response) {
    var employees = {}
    Object.keys(response.data).map(function (key, i) {
        var e = response.data[key]
        if (!e.avatar.includes('//')) {
            e.avatar = css_image_url + 'avatar/' + e.avatar
        }
        e.image = 'url(' + e.avatar + ')'

        employees[e.id] = e
    })

    return employees
}

function loadRolesOffline(context) {
    let roles = []
    return db_roles
        .iterate(function (value, key, iterationNumber) {
            roles[key] = value
        })
        .then(function () {
            if (_.size(_.keys(roles)) > 0) {
                context.commit('setRoles', roles)
            }
            return false
        })
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
