import Vue from 'vue'
import i18n from '@/i18n'
import { Api } from '@/core/api.js'
import localforage from 'localforage'
import router from '@/router'

const api = new Api()

const db_legal_conditions = localforage.createInstance({ name: 'alexdb', storeName: 'legal_conditions' })
const dbEmployeeLoginLogs = localforage.createInstance({ name: 'alexdb', storeName: 'employee_login_logs' })
const db_login = localforage.createInstance({ name: 'alexdb', storeName: 'login' })
const db_locations_auditor = localforage.createInstance({ name: 'alexdb', storeName: 'db_locations_auditor' })

// Triquiñuela para resetear el state
const getDefaultState = () => {
    return {
        hasLoginUser: false,
        local: {
            token: null,
            location: {},
            user: {},
            account: {},
            employee_id: null
        },
        locations: [],
        auditor: localStorage.getItem('auditorInfo') ? JSON.parse(localStorage.getItem('auditorInfo')) : false,
        loadingLoginAuditor: false,
        loginUserVisible: false,
        refreshLoginLayout: true
    }
}

// initial state
const state = getDefaultState()

// load information from localForage
;(async () => {
    state.local.token = await db_login.getItem('token')
    state.local.location = await db_login.getItem('location')
    state.local.account = await db_login.getItem('account')
    state.local.user = await db_login.getItem('user')

    const employee_id = await db_login.getItem('employee_id')
    state.hasLoginUser = !!employee_id
    state.local.employee_id = employee_id

    await db_locations_auditor.iterate(function (value, key, iterationNumber) {
        state.locations.push(value)
    })
})()

// getters
const getters = {
    getHasLoginUser: (state, getters, rootState) => {
        return state.hasLoginUser
    },
    getLocations: (state, getters, rootState) => {
        return state.locations
    },
    getLocalToken: (state, getters, rootState) => {
        return state.local.token
    },
    getLocalLocation: (state, getters, rootState) => {
        return state.local.location
    },
    getLocalAccount: (state, getters, rootState) => {
        return state.local.account
    },
    getLocalEmployee: (state, getters, rootState) => {
        return typeof state.local.employee_id !== 'undefined' ? state.local.employee_id : false
    },
    getUserInformation: (state, getters, rootState) => {
        return state.local.user
    },
    getHasAccessApp: (state, getters, rootState) => {
        let hasAccess = false
        if (state.local.user) {
            hasAccess = !!state.local.user.password
        }
        return hasAccess
    },
    getAuditor(state, getters, rootState) {
        return state.auditor
    },
    getAuditorEmployee(state, getters, rootState) {
        return state.auditor.employee_id ? state.auditor.employee_id : false
    },
    getLoadingLoginAuditor(state, getters, rootState) {
        return state.loadingLoginAuditor
    },

    getLoginUserVisible(state, getters, rootState) {
        return state.loginUserVisible
    },

    getRefreshLoginLayout(state, getters, rootState) {
        return state.refreshLoginLayout
    }
}

// actions
const actions = {
    // Commonisimos, está en todos los modules
    getCountSync(context) {
        return new Promise((resolve, reject) => {
            var count = 0
            dbEmployeeLoginLogs
                .iterate(function (value, key, iterationNumber) {
                    count++
                })
                .then(function () {
                    resolve(count)
                })
        })
    },

    resetState({ commit }) {
        commit('resetState')
    },

    checkLogin(context, params) {
        return api.post('employee/login', { employee: params.employee, password: params.password }).then(function (response) {
            response.emp_id = params.employee
            // context.commit('login', response)
        })
    },

    doLogin(context, params) {
        params.mq = typeof params.mq !== 'undefined' ? params.mq : 0

        if (fnCheckConnection()) {
            return api
                .post('employee/login', { employee: params.employee, password: params.password, mq: params.mq })
                .then(function (response) {
                    if (response.status) {
                        response.emp_id = params.employee
                        response.token = typeof response.data.token !== 'undefined' ? response.data.token : response.data
                        response.user = context.rootState.employee.employees[params.employee]
                        context.commit('login', response)
                        context.commit('login/setToken', response.token, { root: true })
                        context.commit('setLicenseEndDate', response.license_end_date, { root: true })
                        if (context.rootGetters['getScreenlockFlag']) {
                            context.dispatch('login/getScreenlockTasks', {}, { root: true })
                        }

                        const rol = context.rootGetters['employee/getRol'](context.state.local.user.rol)
                        if (rol.level >= 8) {
                            debug = 1
                            localStorage.setItem('debug', 1)
                        }

                        // force to update templates and library
                        let refresh = context.rootGetters['getRefresh']
                        refresh.library = true
                        context.commit('setUpdates', refresh, { root: true })

                        const tools = context.rootGetters['login/getConfig'].tools

                        if (tools.includes(3)) {
                            context.dispatch('label/sendFailedPrintErrors', {}, { root: true })
                            context.dispatch('label/loadLabels', {}, { root: true })
                        }

                        context.dispatch('sendFailedLogins')

                        context.dispatch('login/loadTimeZonesAndLanguages', {}, { root: true })

                        context.commit('setMoment', {}, { root: true })

                        context.dispatch('login/calculateTrialDaysLeft', {}, { root: true })

                        // context.commit('employee/setConditionsVersion', { employee_id: params.employee, conditions_version: response.data.legal_version }, { root: true })
                        if (context.rootGetters.getIsAndroid && FirebasePlugin) {
                            // console.log( "SETTING Employee INFO Analytics & Crashlytics" );
                            FirebasePlugin.setUserProperty('employee', response.emp_id)
                        }
                    } else {
                        context.dispatch('loginOffline', params)
                    }
                })
                .then(function () {
                    if (context.state.hasLoginUser) {
                        context.dispatch('showReleases')
                        return true
                    } else {
                        return false
                    }
                })
                .catch((err) => {
                    logError(err)
                    return context.dispatch('loginOffline', params)
                })
        } else {
            return context.dispatch('loginOffline', params)
        }
    },

    doLoginAuditor(context, params) {
        params.mq = typeof params.mq !== 'undefined' ? params.mq : 0

        if (fnCheckConnection()) {
            context.commit('loadingLoginAuditor', true)
            return api
                .post('login/auditor/' + params.location_id, { mq: params.mq })
                .then(async function (response) {
                    if (response.status) {
                        log('>> LOGIN AUDITOR', response)
                        context.commit('login/setToken', response.token, { root: true })

                        await context.dispatch('employee/loadRoles', {}, { root: true })
                        await context.dispatch('employee/loadEmployees', { force: true }, { root: true })

                        response.emp_id = response.data.employee
                        response.user = context.rootState.employee.employees[response.data.employee]
                        context.commit('login/login', response, { root: true })
                        const loginObj = {
                            token: response.token,
                            status: response.status,
                            emp_id: response.emp_id,
                            user: response.user,
                            account_id: response.data.account_id,
                            auditor: true
                        }
                        context.commit('login', loginObj)
                        state.local.token = response.token
                        state.local.employee_id = response.emp_id
                        state.local.user = response.user
                        state.local.account = response.data.account_id

                        var dt = response.data

                        const location = { id: dt.id, name: dt.name, avatar: dt.avatar, identification: dt.identification, username: dt.username, timezone: dt.timezone, email: dt.email, token: response.token }

                        state.local.location = location

                        context.commit('setLicenseEndDate', response.license_end_date, { root: true })

                        const rol = context.rootGetters['employee/getRol'](context.state.local.user.rol)
                        if (rol.level >= 8) {
                            debug = 1
                            localStorage.setItem('debug', 1)
                        }
                        await context.dispatch('generateStructureAuditor', response)
                        // force to update templates and library
                        let refresh = context.rootGetters['getRefresh']
                        refresh.library = true
                        context.commit('setUpdates', refresh, { root: true })

                        const tools = context.rootGetters['login/getConfig'].tools

                        if (response.data.issue_state) {
                            context.commit('setIssueStates', response.data.issue_state, { root: true })
                        }

                        /* if (tools.includes(1)) {
                            context.dispatch('checklist/loadTemplates', {}, { root: true })
                        } */

                        /* if (tools.includes(2)) {
                            context.dispatch('issues/loadTemplates', {}, { root: true })
                            const issuesStates = context.rootGetters['getIssueStates']
                            if(Object.keys(issuesStates).length > 0){
                                log('>> LOAD INIT ISSUES',issuesStates)
                                const states = Object.keys(issuesStates)
                                states.forEach((stateId) =>{
                                    log('>> ISSUE state', stateId)
                                    context.dispatch('issues/loadAll',{
                                        date: 'all',
                                        type: stateId,
                                        template_id: 'all',
                                        number_page: 1,
                                        number_items: 10
                                    },{ root: true })
                                })
                            }
                        } */

                        /* if (tools.includes(4)) {
                            context.dispatch('register/loadTemplates', {}, { root: true })
                        } */

                        /* if (tools.includes(7)) {
                            context.dispatch('audit/loadTemplates', {}, { root: true })
                        } */

                        /* if (tools.includes(1) || tools.includes(2) || tools.includes(4) || tools.includes(7)) {
                            context.dispatch('tasks/loadAll', { cType: 1, date: moment().unix() }, { root: true })
                        }

                        if (tools.includes(3)) {
                            context.dispatch('label/sendFailedPrintErrors', {}, { root: true })
                            context.dispatch('label/loadLabels', {}, { root: true })
                        } */

                        context.dispatch('sendFailedLogins')

                        context.dispatch('login/loadTimeZonesAndLanguages', {}, { root: true })

                        context.commit('setMoment', {}, { root: true })

                        context.dispatch('login/calculateTrialDaysLeft', {}, { root: true })

                        context.dispatch('login/loadData', response.data, { root: true })

                        // context.commit('employee/setConditionsVersion', { employee_id: params.employee, conditions_version: response.data.legal_version }, { root: true })
                        if (context.rootGetters.getIsAndroid && FirebasePlugin) {
                            // console.log( "SETTING Employee INFO Analytics & Crashlytics" );
                            FirebasePlugin.setUserProperty('employee', response.emp_id)
                        }

                        const timeout = response.data.config && typeof response.data.config.timeout !== 'undefined' && response.data.config.timeout ? parseInt(response.data.config.timeout) * 60 : 43200 * 60
                        context.commit('setTimeout', timeout, { root: true })

                        context.commit('login/setIsLoadedLocation', true, { root: true })
                        context.commit('loadingLoginAuditor', false)
                    } else {
                        context.dispatch('loginOffline', params)
                        context.commit('loadingLoginAuditor', false)
                    }
                })
                .then(function () {
                    if (context.state.hasLoginUser) {
                        context.dispatch('showReleases')
                        return true
                    } else {
                        return false
                    }
                })
                .catch((err) => {
                    logError(err)
                    context.commit('loadingLoginAuditor', false)
                    return context.dispatch('loginOffline', params)
                })
        } else {
            return context.dispatch('loginOffline', params)
        }
    },

    async generateStructureAuditor(context, response) {
        log('>> generateStructureAuditor', response)
        if (typeof response.data.current_time !== 'undefined' && response.data.current_time) {
            const currentTime = moment(moment(response.data.current_time).format('YYYY-MM-DD HH:mm:ss'))
            const now = moment(moment().format('YYYY-MM-DD HH:mm:ss'))
            const difference = Math.round(currentTime.diff(now, 'minutes') / 60) * 60
            context.commit('setServerTimeDifference', difference, { root: true })
        }

        context.commit('setTimeZoneName', response.data.timezone_name, { root: true })
        context.commit('setVersionCurrent', response.data.version_current, { root: true })
        context.commit('setVersionMin', response.data.version_min, { root: true })
        context.commit('setVersionRecommended', response.data.version_recommended, { root: true })

        //   store.dispatch("checkChangesPrint", response.data.printer);
        context.dispatch('login/loadData', response.data, { root: true })
        context.dispatch('loadAppIcon', { path: response.data.app_icon, lastUpdate: response.data.app_icon_last_update * 1000 }, { root: true })
        context.dispatch('loadExternalTools', response.data.menu_items, { root: true })
        context.dispatch('pusher/init', {}, { root: true })
        var printer = Object.assign(response.data.printer)
        log('SETTING PRINTER', printer)
        // context.commit('printer/setPrinterData', printer, { root: true })
        context.dispatch('printer/savePrinterData', printer, { root: true })

        if (response.data.config && response.data.config.home) {
            context.commit('setCustomHome', response.data.config.home, { root: true })
        } else {
            context.commit('setCustomHome', false, { root: true })
        }

        // free labelling
        const freeLabelling = response.data.config && typeof response.data.config.freeLabelling !== 'undefined' && response.data.config.freeLabelling ? 1 : 0
        context.commit('setFreeLabelling', freeLabelling, { root: true })

        const sensorLimitation = response.data.config && typeof response.data.config.sensorLimitation !== 'undefined' && response.data.config.sensorLimitation ? response.data.config.sensorLimitation : 0
        context.commit('setSensorLimitation', sensorLimitation, { root: true })

        const relatedLabels = response.data.config && typeof response.data.config.relatedLabels !== 'undefined' && response.data.config.relatedLabels ? response.data.config.relatedLabels : 0
        context.commit('setRelatedLabels', relatedLabels, { root: true })

        const dateLimitation = response.data.config && typeof response.data.config.dateLimitation !== 'undefined' && response.data.config.dateLimitation ? response.data.config.dateLimitation : 0
        context.commit('setDateLimitation', dateLimitation, { root: true })

        const canCreateUsers = response.data.config && typeof response.data.config.canCreateUsers !== 'undefined' ? response.data.config.canCreateUsers : 1
        context.commit('setCanCreateUsers', canCreateUsers, { root: true })

        const maxLabelsPrint = response.data.config && typeof response.data.config.maxLabelsPrint !== 'undefined' && response.data.config.maxLabelsPrint ? response.data.config.maxLabelsPrint : 100
        context.commit('setMaxLabelsPrint', maxLabelsPrint, { root: true })

        const callLoginUsers = response.data.config && typeof response.data.config.callLoginUsers !== 'undefined' ? response.data.config.callLoginUsers : 0
        context.commit('setCallLoginUsers', callLoginUsers, { root: true })

        const timeout = response.data.config && typeof response.data.config.timeout !== 'undefined' && response.data.config.timeout ? parseInt(response.data.config.timeout) * 60 : idle_time
        context.commit('setTimeout', timeout, { root: true })

        const license_end_date = response.data && typeof response.data.license_end_date !== 'undefined' && response.data.license_end_date ? response.data.license_end_date : false

        context.commit('setLicenseEndDate', license_end_date, { root: true })

        const note_img = response.data.config && typeof response.data.config.note_img !== 'undefined' && response.data.config.note_img ? response.data.config.note_img : 'all'
        context.commit('setNoteImg', note_img, { root: true })

        const offline = response.data.offline ? response.data.offline : 0
        console.log('>> OFFLINE', offline)
        context.commit('setOffline', offline, { root: true })

        context.commit('setLocation', {
            account_name: response.data.account_name,
            id: response.data.id,
            name: response.data.name,
            avatar: response.data.avatar,
            identification: response.data.identification,
            username: response.data.username,
            timezone: response.data.timezone,
            email: response.data.email,
            token: response.data.token,
            city: response.data.city,
            state: response.data.state,
            address: response.data.address,
            cp: response.data.cp
        })

        context.commit('setAccount', response.data.account_id)

        context.commit('login/setReleases', response.data.releases, { root: true })
        response.data.token = response.token
        context.commit('login/login', response, { root: true })

        context.dispatch('login/loadTimeZonesAndLanguages', {}, { root: true })

        context.commit('login/setTrialEndDate', response.data.trial_end_date, { root: true })
        context.dispatch('login/calculateTrialDaysLeft', {}, { root: true })
        context.commit('login/setTrialMessage', response.data.trial_message, { root: true })

        context.commit('login/setWrongIp', false, { root: true })
        context.commit('login/setisLoaded', true, { root: true })
    },

    loginOffline(context, params) {
        // Offline
        var employees = context.rootState.employee.employees
        var logged = false
        Object.keys(employees).forEach(function (key) {
            var e = employees[key]

            if (e.id == params.employee && e.password == params.password) {
                logged = e
            }
        })

        if (logged) {
            var employee = {
                id: context.state.local.location.id,
                account_id: context.state.local.account ? context.state.local.account : false,
                name: context.state.local.location.name,
                employee: {
                    id: params.employee,
                    name: logged.name,
                    avatar: logged.avatar
                },
                exp: moment().add(86400, 'minutes').unix()
            }

            var jwt = require('jwt-simple')
            var token = jwt.encode(employee, process.env.VUE_APP_JWT_KEY)
            var response = {
                status: true,
                token: token,
                emp_id: params.employee,
                user: context.rootState.employee.employees[params.employee]
            }

            const iCurrentDate = moment().format('YYYY-MM-DD HH:mm:ss')

            // save into local forage
            dbEmployeeLoginLogs.setItem(iCurrentDate, {
                employee_id: employee.employee.id,
                start_date: iCurrentDate,
                end_date: null,
                mq: params.mq
            })

            context.commit('login', response)

            if (context.rootGetters.getIsAndroid && FirebasePlugin) {
                // console.log( "SETTING Employee INFO Analytics & Crashlytics" );
                FirebasePlugin.setUserProperty('employee', response.emp_id)
            }

            context.dispatch('login/calculateTrialDaysLeft', {}, { root: true })

            if (context.rootGetters['getScreenlockFlag']) {
                context.dispatch('login/getScreenlockTasks', {}, { root: true })
            }

            return true
        }

        return false
    },

    doLogout(context, params) {
        const self = this

        context.commit('setRefreshProducts', true, { root: true })
        context.commit('setRefreshLibrary', true, { root: true })
        if (typeof params.external_login_complete === 'undefined') {
            if (fnCheckConnection()) {
                return api
                    .post('employee/logout', { employee: params.employee })
                    .then(function (response) {
                        context.commit('logout', response)
                        context.commit('printer/setIsValidatebPac', false, { root: true })
                    })
                    .then(function () {
                        // reset task date for login user
                        context.commit('tasks/setDate', false, { root: true })
                        if (!context.state.hasLoginUser) {
                            if (context.rootGetters.getIsAndroid && FirebasePlugin) {
                                // console.log( "SETTING Employee logout INFO Analytics & Crashlytics" );
                                FirebasePlugin.setUserProperty('employee', null)
                            }
                            if (parseInt(context.rootGetters.getCallLoginUsers) == 1 && typeof params.external_login_complete === 'undefined' && !context.getters.getUserInformation.is_admin) {
                                if (!context.rootGetters.getIsApp) {
                                    window.location.href = process.env.VUE_APP_API_URL + 'logout/externaluser?authorization=' + context.rootGetters['login/getToken'] + '&platform=2'
                                } else {
                                    cordova.InAppBrowser.open(process.env.VUE_APP_API_URL + 'logout/externaluser?authorization=' + context.rootGetters['login/getToken'] + '&platform=1', '_system')
                                }
                            } else {
                                return true
                            }
                        } else {
                            if (parseInt(context.rootGetters.getCallLoginUsers) == 1 && typeof params.external_login_complete === 'undefined' && !context.getters.getUserInformation.is_admin) {
                                if (!context.rootGetters.getIsApp) {
                                    window.location.href = process.env.VUE_APP_API_URL + 'logout/externaluser?authorization=' + context.rootGetters['login/getToken'] + '&platform=2'
                                } else {
                                    cordova.InAppBrowser.open(process.env.VUE_APP_API_URL + 'logout/externaluser?authorization=' + context.rootGetters['login/getToken'] + '&platform=1', '_system')
                                }
                            } else {
                                return false
                            }
                        }
                    })
                    .catch(function (err) {
                        const loc = context.rootGetters['login/getLocation']
                        const account = context.rootGetters['login/getAccount']
                        var location = {
                            id: loc.id,
                            account_id: account ? account.id : false,
                            name: loc.name,
                            employee: false,
                            exp: moment().add(86400, 'minutes').unix()
                        }
                        log('TOKEN', location)
                        var jwt = require('jwt-simple')
                        var token = jwt.encode(location, process.env.VUE_APP_JWT_KEY)
                        return context.commit('logout', { data: token })
                    })
            } else {
                dbEmployeeLoginLogs.iterate(function (value, key, iterationNumber) {
                    if (value.employee_id == params.employee && !value.end_date) {
                        value.end_date = moment().format('YYYY-MM-DD HH:mm:ss')
                        dbEmployeeLoginLogs.setItem(key, value)
                    }
                })
                const loc = context.rootGetters['login/getLocation']
                const account = context.rootGetters['login/getAccount']
                const location = {
                    id: loc.id,
                    account_id: account ? account.id : false,
                    name: loc.name,
                    employee: false,
                    exp: moment().add(86400, 'minutes').unix()
                }
                const jwt = require('jwt-simple')
                const token = jwt.encode(location, process.env.VUE_APP_JWT_KEY)
                return context.commit('logout', { data: token })
            }
        } else {
            return true
        }
    },

    doLogoutAuditor(context, params) {
        /*
        TODO:
            1. SI esta logueado con un local en especifico, se debe cerrar la sesion de ese local, pero no perder la sesion de auditor
            2. SI esta logueado como auditor y no tiene ningun local seleccionado logueado, se debe cerrar la sesion de auditor y volver al login
         */

        //EL 2 ES BORRAR objecto local en loginUser
        const self = this

        context.commit('setRefreshProducts', true, { root: true })
        context.commit('setRefreshLibrary', true, { root: true })
        if (typeof params.external_login_complete === 'undefined') {
            if (fnCheckConnection()) {
                return api
                    .post('employee/logout', { employee: params.employee })
                    .then(function (response) {
                        context.commit('logout', response)
                        context.commit('printer/setIsValidatebPac', false, { root: true })
                    })
                    .then(function () {
                        // reset task date for login user
                        context.commit('tasks/setDate', false, { root: true })
                        if (!context.state.hasLoginUser) {
                            if (context.rootGetters.getIsAndroid && FirebasePlugin) {
                                // console.log( "SETTING Employee logout INFO Analytics & Crashlytics" );
                                FirebasePlugin.setUserProperty('employee', null)
                            }
                            if (parseInt(context.rootGetters.getCallLoginUsers) == 1 && typeof params.external_login_complete === 'undefined' && !context.getters.getUserInformation.is_admin) {
                                if (!context.rootGetters.getIsApp) {
                                    window.location.href = process.env.VUE_APP_API_URL + 'logout/externaluser?authorization=' + context.rootGetters['login/getToken'] + '&platform=2'
                                } else {
                                    cordova.InAppBrowser.open(process.env.VUE_APP_API_URL + 'logout/externaluser?authorization=' + context.rootGetters['login/getToken'] + '&platform=1', '_system')
                                }
                            } else {
                                return true
                            }
                        } else {
                            if (parseInt(context.rootGetters.getCallLoginUsers) == 1 && typeof params.external_login_complete === 'undefined' && !context.getters.getUserInformation.is_admin) {
                                if (!context.rootGetters.getIsApp) {
                                    window.location.href = process.env.VUE_APP_API_URL + 'logout/externaluser?authorization=' + context.rootGetters['login/getToken'] + '&platform=2'
                                } else {
                                    cordova.InAppBrowser.open(process.env.VUE_APP_API_URL + 'logout/externaluser?authorization=' + context.rootGetters['login/getToken'] + '&platform=1', '_system')
                                }
                            } else {
                                return false
                            }
                        }
                    })
                    .catch(function (err) {
                        const loc = context.rootGetters['login/getLocation']
                        const account = context.rootGetters['login/getAccount']
                        var location = {
                            id: loc.id,
                            account_id: account ? account.id : false,
                            name: loc.name,
                            employee: false,
                            exp: moment().add(86400, 'minutes').unix()
                        }
                        log('TOKEN', location)
                        var jwt = require('jwt-simple')
                        var token = jwt.encode(location, process.env.VUE_APP_JWT_KEY)
                        return context.commit('logout', { data: token })
                    })
            } else {
                dbEmployeeLoginLogs.iterate(function (value, key, iterationNumber) {
                    if (value.employee_id == params.employee && !value.end_date) {
                        value.end_date = moment().format('YYYY-MM-DD HH:mm:ss')
                        dbEmployeeLoginLogs.setItem(key, value)
                    }
                })
                const loc = context.rootGetters['login/getLocation']
                const account = context.rootGetters['login/getAccount']
                const location = {
                    id: loc.id,
                    account_id: account ? account.id : false,
                    name: loc.name,
                    employee: false,
                    exp: moment().add(86400, 'minutes').unix()
                }
                const jwt = require('jwt-simple')
                const token = jwt.encode(location, process.env.VUE_APP_JWT_KEY)
                return context.commit('logout', { data: token })
            }
        } else {
            return true
        }
    },

    /**
     * This method is for updateing condition version (login)
     * @param {String} employee_id
     */
    async showPopupConditions({ dispatch, commit, getters, rootGetters }, params) {
        let language = rootGetters['getLanguage']
        let conditionsVersion = await rootGetters['login/getConditionsVersion']
        let popupBody = ''
        let acceptedInOfflineMode = false

        // iterate local database
        db_legal_conditions
            .iterate((legal_version, employee_id, iterationNumber) => {
                // check if user has accepted current legal conditions when he was offline
                if (legal_version == conditionsVersion) {
                    // if is user logged don't show popup
                    if (employee_id == params.employee_id) {
                        acceptedInOfflineMode = true
                    }

                    // if device has connection, update information
                    if (fnCheckConnection()) {
                        // update into server
                        api.post(`employee/${employee_id}/setlegalversion`, { legal_version: conditionsVersion }).then((response) => {
                            commit('employee/setConditionsVersion', { employee_id: employee_id, conditions_version: conditionsVersion }, { root: true })
                        })

                        // remove element from db
                        db_legal_conditions.removeItem(employee_id).then(function () {
                            // sync offline legal conditions for other users
                            dispatch('syncLegalConditions')
                        })
                    }

                    // incorrect version, remove it
                } else {
                    db_legal_conditions.removeItem(employee_id).then(function () {
                        // sync offline legal conditions for other users
                        dispatch('syncLegalConditions')
                    })
                }
            })
            .then(function () {
                // check if user has accepted current legal conditions when he was offline
                if (!acceptedInOfflineMode) {
                    return api.get(`employee/conditions/${language}`).then(function (response) {
                        if (response.data) {
                            popupBody = response.data

                            Vue.prototype.$popup.confirm({
                                title: i18n.t('conditions.title'),
                                textSave: i18n.t('conditions.yes'),
                                textCancel: i18n.t('conditions.no'),
                                message: popupBody,
                                customClass: 'popup-small',
                                closable: false,
                                blocking: true,
                                callSave: function () {
                                    // update user legal conditions
                                    commit('employee/setConditionsVersion', { employee_id: params.employee_id, conditions_version: conditionsVersion }, { root: true })

                                    if (fnCheckConnection()) {
                                        return api.post(`employee/${params.employee_id}/setlegalversion`, { legal_version: conditionsVersion })
                                    } else {
                                        // save into local forge
                                        db_legal_conditions.setItem(params.employee_id, conditionsVersion)
                                    }
                                },

                                callCancel: function () {
                                    // close first popup
                                    Vue.prototype.$popup.close()

                                    // if user click cancel, show error popup
                                    Vue.prototype.$popup.alert({
                                        title: i18n.t('conditions.error.title'),
                                        message: i18n.t('conditions.error.message'),
                                        textSave: i18n.t('conditions.error.confirm'),
                                        closable: false,
                                        blocking: true,
                                        callSave: function () {
                                            dispatch('doLogout', { employee: params.employee_id })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
    },

    /**
     * View conditions from user page
     */
    viewConditions({ dispatch, commit, getters, rootGetters }, params) {
        let language = rootGetters['getLanguage']
        let popupBody = ''
        return api.get(`employee/conditions/${language}`).then(function (response) {
            if (response.data) {
                popupBody = response.data
                Vue.prototype.$popup.alert({
                    title: i18n.t('conditions.title2'),
                    message: popupBody,
                    textSave: i18n.t('conditions.yes2'),
                    customClass: 'popup-small',
                    closable: true,
                    blocking: false
                })
            }
        })
    },

    async syncLegalConditions({ dispatch, commit, getters, rootGetters }, params) {
        let conditionsVersion = await rootGetters['login/getConditionsVersion']

        // iterate local database
        db_legal_conditions.iterate((legal_version, employee_id, iterationNumber) => {
            // check if user has accepted current legal conditions when he was offline
            if (legal_version == conditionsVersion) {
                // if device has connection, update information
                if (fnCheckConnection()) {
                    // update into server
                    api.post(`employee/${employee_id}/setlegalversion`, { legal_version: conditionsVersion })

                    // remove element from db
                    db_legal_conditions.removeItem(employee_id)
                }

                // incorrect version, remove it
            } else {
                db_legal_conditions.removeItem(employee_id)
            }
        })
    },

    sendFailedLogins(context) {
        if (fnCheckConnection()) {
            const aLogins = []

            return dbEmployeeLoginLogs
                .iterate(function (value, key, iterationNumber) {
                    aLogins.push(value)
                })
                .then(function () {
                    if (aLogins.length == 0) {
                        return true
                    }

                    return api.post('employee/login/multiple', { data: JSON.stringify(aLogins) }).then(function (response) {
                        return dbEmployeeLoginLogs.clear().then(function () {
                            return true
                        })
                    })
                })
        }
    },

    showReleases(context) {
        const news = context.rootGetters['login/getReleases']()
        if (Object.values(news).length > 0) {
            const releasesViewed = localStorage.getItem('releases_viewed') ? JSON.parse(localStorage.getItem('releases_viewed')) : []
            const newReleases = []

            for (const release of news) {
                if (!releasesViewed.includes(release.id)) {
                    newReleases.push(release)
                }
            }

            if (newReleases.length > 0) {
                const release = newReleases[0]

                Vue.prototype.$popup.release({
                    title: release.title,
                    data: release.text,
                    image: release.image,
                    callSave: function () {
                        router.push({ name: 'Release', params: { selected: release.id } })
                        Vue.prototype.$popup.close()
                    },
                    callCancel: function () {
                        Vue.prototype.$popup.close()
                    }
                })

                releasesViewed.push(release.id)
                localStorage.setItem('releases_viewed', JSON.stringify(releasesViewed))
            }
        }
    }
}

// mutations
const mutations = {
    // Commonisimos, está en todos los modules
    resetState(state) {
        Object.assign(state, getDefaultState())
    },

    login(state, response) {
        if (response.status) {
            state.hasLoginUser = true

            var local = { ...state.local }
            local.token = response.token
            local.employee_id = response.emp_id
            local.user = response.user
            state.local = { ...local }
            Vue.set(state, 'local', { ...local })

            db_login.setItem('token', response.token)
            db_login.setItem('employee_id', response.emp_id)
            db_login.setItem('user', response.user)
        } else {
            state.hasLoginUser = false
        }
    },

    logout(state, response) {
        let local = { ...state.local }
        local.token = response.data
        local.employee_id = false
        local.user = false
        state.local = { ...local }
        db_login.removeItem('employee_id')
        db_login.removeItem('user')
        state.hasLoginUser = false
    },

    setLocation(state, response) {
        var local = { ...state.local }
        local.location = response

        state.local = local
    },

    setLocations(state, response) {
        state.locations = response

        db_locations_auditor.clear()
        response.forEach((location) => {
            db_locations_auditor.setItem(location.username, location)
        })
    },

    setAccount(state, response) {
        var local = { ...state.local }
        local.account = response

        state.local = local
    },

    setAuditor(state, response) {
        state.auditor = response
        localStorage.setItem('auditorInfo', JSON.stringify(response))
        db_login.setItem('auditorInfo', response)
    },

    loadingLoginAuditor(state, response) {
        state.loadingLoginAuditor = response
    },
    
    setVisibleConnect(state, value){
        state.loginUserVisible = value
    },

    setRefreshLayout(state, value) {
        state.refreshLoginLayout = value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}